@import "../variables";

$breakpoint-mobile-points: 600;
$breakpoint-mobile: 600px;
$breakpoint-desktop: 1440px;
$breakpoint-desktop-points: 1440;

@mixin responsive-font ($small, $big) {
    $font-size: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    font-size: #{($small / 16)}rem;
    font-size: clamp(#{($small / 16)}rem, #{($font-size)}, #{($big / 16)}rem);
}

@mixin responsive-padding ($small, $big) {
    $padding: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    padding: #{($small / 16)}rem;
    padding: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
}

.bgvideo {
    height: 100%;    
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: $b-lg) {
        height: calc(100vh - (100vw / 6) * (9 / 16));
    }

    &__iframe {
        width: 100vw;
        height: 56.25vw;

        /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh;

        /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: fixed;
        pointer-events: none;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (min-width: $b-lg) {
            height: calc(100vh - (100vw / 6) * (9 / 16));
            left: initial;
            min-height: calc(100vh - (100vw / 6) * (9 / 16));
            min-width: 100%;
            top: initial;
            transform: scale(1.5);
            transform-origin: center center;
            width: 100%;            
        }
    }
}

.bgvideo-placeholder {
    background: #000;
    max-width: 100vw;
    min-height: 100vh;
    width: 100%;

    @media (min-width: $b-lg) {
        min-height: calc(100vh - (100vw / 6) * (9 / 16));
    }
}

.bgvideo__content {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 20vh;
    z-index: 3;
    @extend .fadein;

    &--loading {
        align-items: center;
        height: 100vh;
        justify-content: center;
        top: 0;
    }
}

.bgvideo__title {
    box-sizing: border-box;
    font-size: 1.5rem;
    @include responsive-padding(16, 60);
    text-align: right;

    @media (min-width: $b-md) {
        font-size: 1.25rem;
    }

    @media (min-width: $b-lg) {
        $small: 1.2rem;
        $big: 1.5rem;
        $font-size: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-desktop)}) / #{(1960 - $breakpoint-desktop-points)}));
        font-size: #{($small / 16)}rem;
        font-size: clamp(#{($small / 16)}rem, #{($font-size)}, #{($big / 16)}rem);    
    }
}

.bgvideo__titlelink {
    color: #fff;
    text-decoration: none;
}