@import "../layout/reset";
@import "../variables";

@mixin responsive-font ($small, $big) {
    $font-size: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    font-size: #{($small / 16)}rem;
    font-size: clamp(#{($small / 16)}rem, #{($font-size)}, #{($big / 16)}rem);
}

@mixin responsive-padding ($small, $big) {
    $padding: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    padding: #{($small / 16)}rem;
    padding: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
}

@mixin responsive-padding-top ($small, $big) {
    $padding: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    padding-top: #{($small / 16)}rem;
    padding-top: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
}

.article {
    align-items: center;
    background: rgba(0, 0, 0, .75);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding-top: #{(120/16)}rem;
    position: relative;
    text-align: center;
    z-index: 3;
    @extend .fadein;

    @media (min-width: $b-lg) {
        min-height: calc(100vh - (100vw / 6) * (2 / 3) - 3px);
    }
}

.article__content {
    box-sizing: border-box;
    max-width: #{(768/16)}rem;
    @include responsive-padding(24, 60);

    @media (max-width: $b-lg) and (orientation: landscape) {
        max-width: #{(960/16)}rem;
        @include responsive-padding(210, 240);
        padding-top: 0 !important;
        padding-bottom: #{(60/16)}rem !important;
    }
}

.article--about p {
    @include responsive-font(16, 21);
    font-style: italic;
    line-height: 1.2em;
    margin-bottom: 1em;
}

.contacts {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.contact {
    display: block;
    @include responsive-font(16, 21);
    margin-bottom: 2em;

    &.contact:last-child {
        margin-bottom: 1em;
        margin-top: 4em;
    }
}

.contact__name {
    font-style: italic;
    font-weight: 400;
}

.contact__title {
    font-size: .75em;
    font-weight: bold;
    margin: 0.2em 0;
    text-transform: uppercase;
}

.contact__mail {
    font-size: .5em;
    font-weight: bold;
}

.contact__phone {
    font-size: .75em;
    font-style: italic;
    font-weight: 700;
    margin-top: 0.2em;
}