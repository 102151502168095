.lightbox {
    background: rgba(0, 0, 0, .75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
}

.lightbox-outer {
    height: calc(100vh - 180px);
    margin: 90px 40px;
}

.lightbox-inner {
    height: 100%;
    position: relative;
    margin: 0 auto;
    max-width: 1480px;
}

.lightbox-close {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 48px;
    height: 88px;
    line-height: 1em;
    padding: 20px;
    position: absolute;
    right: 0;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
    transition: all .2s ease-in-out;
    top: 0;
    width: 88px;
    z-index: 999;
}

.lightbox-close:hover,
.lightbox-close:focus,
.lightbox-close:active {
    opacity: .7;
}