@import "../layout/reset";
@import "../variables";

$breakpoint-mobile-points: 600;
$breakpoint-mobile: 600px;
$breakpoint-desktop-points: 1960;

@mixin responsive-font ($small, $big) {
    $font-size: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    font-size: #{($small / 16)}rem;
    font-size: clamp(#{($small / 16)}rem, #{($font-size)}, #{($big / 16)}rem);
}

@mixin responsive-padding ($small, $big) {
    $padding: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    padding: #{($small / 16)}rem;
    padding: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
}

$menu-hover-opacity: .4;

.logo {
    @include responsive-font(28, 96);
    left: 0;
    line-height: .9em;
    max-width: calc(50vw - 1rem);
    @include responsive-padding(16, 60);
    position: absolute;
    top: 0;
    z-index: 100;
    @extend .fadein;
}

.logo__link {
    color: #fff;
    text-decoration: none;
    transition: $transition-default;

    &:hover {
        opacity: $menu-hover-opacity;
    }
}

.logo__small {
    display: block;
    @include responsive-font(12, 24);
    font-style: italic;
    font-weight: 400;
    line-height: 1em;
    margin-top: .1em;
    @extend .fadein;
}

.menu {
    max-width: calc(50vw - 1rem);
    @include responsive-padding(16, 60);
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    z-index: 100;
    @extend .fadein;
}

.menu__title {
    @include responsive-font(16, 21);
    line-height: 1.2em;
    text-transform: uppercase;
    transition: $transition-default;
}

.menu__link {
    display: block;
    @include responsive-font(14, 18);
    font-weight: bold;
    line-height: 1.2em;
    transition: $transition-default;

    &:last-of-type {
        margin-bottom: 1em;
    }
}

.menu__link,
.menu__link--title {
    color: #fff;
    text-decoration: none;

    &--active,
    &:hover,
    &:active,
    &:focus {
        opacity: $menu-hover-opacity;
    }
}