@import "../layout/reset";
@import "../variables";

@mixin responsive-font ($small, $big) {
    $font-size: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    font-size: #{($small / 16)}rem;
    font-size: clamp(#{($small / 16)}rem, #{($font-size)}, #{($big / 16)}rem);
}

@mixin responsive-padding-x ($small, $big) {
    $padding: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    padding-left: #{($small / 16)}rem;
    padding-right: #{($small / 16)}rem;
    padding-left: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
    padding-right: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
}

@mixin responsive-padding-y ($small, $big) {
    $padding: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    padding-bottom: #{($small / 16)}rem;
    padding-top: #{($small / 16)}rem;
    padding-bottom: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
    padding-top: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
}

.director-name {
    box-sizing: border-box;
    bottom: 0;
    @include responsive-font(32, 72);
    left: 0;
    @include responsive-padding-x(24, 72);
    @include responsive-padding-y(40, 80);
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 2;

    @media (min-width: $b-lg) {
        bottom: calc((100vw / 6) * (2 / 3) - 3px);
    }

    /*
    @media (min-width: $b-xl) {
        @include responsive-font(32, 96);
    }
    */

    @media (max-width: $b-lg) and (orientation: landscape) {
        @include responsive-font(21, 72);
        @include responsive-padding-x(80, 160);
        @include responsive-padding-y(40, 80);
    }
}