@import "layout/reset";
@import "variables";

@font-face {
    font-family: "Eames";
    src: url("../../public/fonts/eames-regular.otf") format("opentype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../../public/fonts/eames-medium.otf") format("opentype");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../../public/fonts/eames-book.otf") format("opentype");
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../../public/fonts/eames-bold.otf") format("opentype");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Eames";
    src: url("../../public/fonts/eames-light-italic.otf") format("opentype");
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}

html,
body {
    background: #000 !important;
    color: #fff;
    font-family: "Eames";
}

.layout--lightbox {
    height: 100vh;
    overflow: hidden;
}

.fadein {
    animation: fadeIn 1s ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@import "components/bgvideo";
@import "components/header";
@import "components/lightbox";
@import "components/spinner";
@import "components/videostrip";

@import "pages/directorpage";
@import "pages/homepage";