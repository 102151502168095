@import "../variables";

$breakpoint-mobile-points: 600;
$breakpoint-mobile: 600px;
$breakpoint-desktop: 1440px;
$breakpoint-desktop-points: 1440;

@mixin responsive-font ($small, $big) {
    $font-size: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    font-size: #{($small / 16)}rem;
    font-size: clamp(#{($small / 16)}rem, #{($font-size)}, #{($big / 16)}rem);
}

@mixin responsive-padding ($small, $big) {
    $padding: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-mobile)}) / #{($breakpoint-desktop-points - $breakpoint-mobile-points)}));
    padding: #{($small / 16)}rem;
    padding: clamp(#{($small / 16)}rem, #{($padding)}, #{($big / 16)}rem);
}

.videostrip {
    @media (min-width: $b-sm) {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
    }
}

.videostripitem {
    display: block;
    height: calc(100vw * (9 / 16));
    min-height: 100px;
    overflow: hidden;
    position: relative;

    &:after {
        background: rgba(0, 0, 0, 0);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: $transition-default;
    }

    &:hover,
    &:focus,
    &:active {
        &:after {
            background: rgba(0, 0, 0, .3);
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    @media (min-width: $b-md) {
        height: calc((100vw / 3) * (9 / 16));
        flex: 0 0 33.33%;
    }

    @media (min-width: $b-lg) {
        height: calc((100vw / 6) * (9 / 16));
        flex: 0 0 calc(100% / 6);
    }
}

.videostripitem__content {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    @include responsive-padding(16, 32);
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 2;
}

.videostripitem__title {
    color: #fff;
    font-size: 1.5rem;

    @media (min-width: $b-md) {
        font-size: 1.25rem;
    }

    @media (min-width: $b-lg) {
        $small: 1.2rem;
        $big: 1.5rem;
        $font-size: calc(#{($small / 16)}rem + #{($big - $small)} * ((100vw - #{($breakpoint-desktop)}) / #{(1960 - $breakpoint-desktop-points)}));
        font-size: #{($small / 16)}rem;
        font-size: clamp(#{($small / 16)}rem, #{($font-size)}, #{($big / 16)}rem);
    }
}